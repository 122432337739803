 .errorBody {
   padding-top: 3% !important;
   /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
   text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.75);
   text-align: center !important;
 }

 .errorText {
   font-weight: 600 !important;
   /* color: #5d5d5d !important; */
   letter-spacing: -0.02em !important;
   margin-bottom: 15px !important;
   margin-top: 25px !important;
 }

 .iconLargeBI {
   font-size: 120px;
   line-height: 1;
 }

 .iconLarge {
   height: 132px;
   width: 132px;
 }

 .info {
   color: #5594cf;
   fill: #5594cf;
 }

 .error {
   color: #c92127;
   fill: #c92127;
 }

 .warning {
   color: #ffcc33;
   fill: #ffcc33;
 }

 .success {
   color: #5aba47;
   fill: #5aba47;
 }

 .descriptionText {
   /* color: #707070; */
   letter-spacing: -0.01em;
   font-size: 1.45em;
   line-height: 1.7;
 }

 .footer {
   /* color: #707070; */
   margin-bottom: 20px;
   font-size: 0.85em;
 }

 .animated {
   -webkit-animation-duration: 1s;
   animation-duration: 1s;
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
 }

 .fadeIn {
   -webkit-animation-name: fadeIn;
   animation-name: fadeIn;
 }

 .delay {
   -webkit-animation-delay: 0.2s;
   animation-delay: 0.2s;
 }

 @keyframes fadeIn {
   from {
     opacity: 0;
   }

   to {
     opacity: 1;
   }
 }