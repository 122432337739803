[data-bs-theme=light] {
  --firstpanel: #8DABC4;
  --secondarypanel: #ECF5FD;
  --loadingbackground: rgba(255, 255, 255, 0.7);
}

[data-bs-theme=dark] {
  --firstpanel: var(--bs-tertiary-bg);
  --secondarypanel: rgba(59, 63, 92, 0.4);
  --loadingbackground: rgba(0, 0, 0, 0.6);
}

.text_green {
  color: #00ff00;
}

.btn-our {
  min-width: 9em;
}

/*----------------------------------------------------- Bootstrap -------*/
.custom-tooltip {
  --bs-tooltip-bg: var(--primarycolor);
  --bs-tooltip-opacity: 1;
}

.form-gutter {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0.8rem;
}

.form-group {
  margin-bottom: 0.8rem;
}

.form-error {
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.form-check,
.form-check-reverse {
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  align-items: center !important;
}

.form-check-reverse {
  flex-direction: row-reverse;
}

.form-check .form-check-label {
  margin-left: 0.4em;
}

.form-check-reverse .form-check-label {
  margin-right: 0.5em;
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
}

.form-check .form-check-input {
  margin: 0em;
}

.form-switch .form-check-input {
  width: 2.2em;
  height: 1.3em;
}

.form-check-input:checked.form-check-input-danger {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-check+.btn-primary:hover,
.btn-check:checked+.btn-primary {
  opacity: 0.75;
  border-color: var(--primarycolor) !important;
}

.btn-group-split>.btn:not(:last-child) {
  margin-right: 3px;
}

.btn-check+.btn {
  box-shadow: none !important;
}

.quick-period .btn-check+.btn {
  min-width: 3.5rem;
}

.text-light-new,
.form-label,
form label:not(.btn) {
  color: #8DABC4 !important;
}

.iframe-modal .modal-header,
.iframe-modal-full .modal-header {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  /*border: 0;*/
  background-color: var(--mainbackground);
}

.iframe-modal .modal-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  height: 87vh;
}

@media (min-width: 1500px) {
  .iframe-modal .modal-xl {
    max-width: 1500px;
  }
}

@media (min-width: 1700px) {
  .iframe-modal .modal-xl {
    max-width: 1600px;
  }
}

.iframe-modal-full .modal-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  height: calc(100vh - 0.3rem - 1.7rem);
  max-height: calc(100vh - 0.3rem - 1.7rem);
}

.iframe-modal-full .modal-xl {
  margin: 0;
  max-width: 100vw;
}

/*----------------------------------------------------- Table and DataTable*/
div.dataTables_processing div {
  display: none;
}

div.dataTables_info,
div.dataTables_filter,
div.dataTables_length,
div.dataTables_paginate li:not(.active) a,
table.dataTable thead th {
  color: #8DABC4;
  font-weight: normal;
}

@media (max-width: 576px) {
  .dataTables_filter {
    margin-top: 0.5rem !important;
  }
}

.table th,
.table td {
  vertical-align: middle !important;
}

.btn-excel {
  background-color: #107c41 !important;
  border-color: #107c41 !important;
}

.btn-excel:hover {
  border-color: #107c41 !important;
  box-shadow: 0 8px 25px -8px black !important;
}

.table:not(.table-sm) thead th,
.table:not(.table-sm) tfoot th,
.table:not(.table-sm) td,
.table:not(.table-sm) th {
  padding: 0.95em 0.95em;
  border-color: var(--bs-border-color-translucent);
}

.table:not(.table-borderless):not(.table-bordered) {
  border-top: 1px solid var(--bs-border-color-translucent);
}

.td-btn-action {
  width: 35px !important;
  max-width: 35px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.td-btn-dropdown,
.td-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.td-div-flex {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex !important;
  height: auto !important;
  justify-content: center;
  align-items: center !important;
}

.td-div-flex div {
  width: 50%;
  height: 100% !important;
  display: flex;
}

.td-div-flex div.count {
  justify-content: flex-end;
}

/*----------------------------------------------------- wait window Begin*/
#loading {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--loadingbackground);
  z-index: 1000;
}

#loadingcontent {
  display: table;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#loadingspinner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  text-align: center;
  font-size: larger;
  font-weight: bold;
  padding-top: 30px;
}

/*----------------------------------------------------- Карточка объектов ------------*/
.our-nav.no-gutters {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid var(--bs-border-color-translucent);
}

.our-nav li {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 0.85rem !important;
  font-weight: 600 !important;
  background-color: var(--firstpanel) !important;
  border-radius: 0px !important;
}

.our-nav li a,
.our-nav li button {
  color: #C5D9E8 !important;
}

.our-nav li a.active,
.our-nav li a:hover,
.our-nav li button.active,
.our-nav li button:hover {
  color: white !important;
  border-bottom: 0 solid !important;
}

.number_block {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 1.1;
}

.number_block table td {
  white-space: nowrap;
}

.number_block small {
  margin-left: 1.5rem;
  color: #8DABC4 !important;
}

.number_block h4 {
  font-size: 1.35rem !important;
  margin-top: 0;
  margin-bottom: 0rem;
}

.status-block {
  background: #FD7B1F;
  color: white;
  font-size: 0.75rem;
  font-weight: normal;
  min-width: 9rem;
  cursor: pointer;
}

.status-block-color {
  background-color: #FD7B1F !important;
  color: white;
}

/*--------------------------------------------------*/